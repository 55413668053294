<template>
  <div>
    <v-container>
      <v-row class="mt-15 pt-10 mb-15">
        <v-col cols="12" md="3">
          <v-card elevation="5">
            <v-row>
              <v-col cols="12" md="4">
                <v-card
                  style="top: -50px; position: relative"
                  color="#0c71af"
                  class="ml-5 overflow-visible"
                  dark
                  height="100%"
                  width="100%"
                >
                  <div class="text-center">
                    <br />
                    <v-img
                      class="ml-5"
                      src="../assets/pending_payment.png"
                      width="60%"
                    >
                    </v-img>
                    <br />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <div class="text-right pr-2">
                  <p style="color: #bdbdbd">Pagos pendientes</p>
                  <p style="color: black" class="text-h4">
                    {{ count_peniding_payment }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-5"></v-divider>
            <div class="ml-5 pb-1">
              <p class="caption" style="color: #bdbdbd">
                <v-icon>mdi-update</v-icon>Actualizado recientemente
              </p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card elevation="5">
            <v-row>
              <v-col cols="12" md="4">
                <v-card
                  style="top: -50px; position: relative"
                  color="#e8501c"
                  class="ml-5 overflow-visible"
                  dark
                  height="100%"
                  width="100%"
                >
                  <div class="text-center">
                    <br />
                    <v-img
                      class="ml-5"
                      src="../assets/payment_recived.png"
                      width="60%"
                    >
                    </v-img>
                    <br />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <div class="text-right pr-2">
                  <p style="color: #bdbdbd">Pagos recibidos</p>
                  <p style="color: black" class="text-h4">
                    {{ count_recived_payment }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-5"></v-divider>
            <div class="ml-5 pb-1">
              <p class="caption" style="color: #bdbdbd">
                <v-icon>mdi-update</v-icon>Actualizado recientemente
              </p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card elevation="5">
            <v-row>
              <v-col cols="12" md="4">
                <v-card
                  style="top: -50px; position: relative"
                  color="#179db0"
                  class="ml-5 overflow-visible"
                  dark
                  height="100%"
                  width="100%"
                >
                  <div class="text-center">
                    <br />
                    <v-img
                      class="ml-5"
                      src="../assets/debt_client.png"
                      width="60%"
                    >
                    </v-img>
                    <br />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <div class="text-right pr-2">
                  <p style="color: #bdbdbd">Clientes con deudas</p>
                  <p style="color: black" class="text-h4">
                    {{ count_deb_client }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-5"></v-divider>
            <div class="ml-5 pb-1">
              <p class="caption" style="color: #bdbdbd">
                <v-icon>mdi-update</v-icon>Actualizado recientemente
              </p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card elevation="5">
            <v-row>
              <v-col cols="12" md="4">
                <v-card
                  style="top: -50px; position: relative"
                  color="#0D47A1"
                  class="ml-5 overflow-visible"
                  dark
                  height="100%"
                  width="100%"
                >
                  <div class="text-center">
                    <br />
                    <v-img
                      class="ml-5"
                      src="../assets/debt_client.png"
                      width="60%"
                    >
                    </v-img>
                    <br />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <div class="text-right pr-2">
                  <p style="color: #bdbdbd">Clientes</p>
                  <p style="color: black" class="text-h4">
                    {{ count_total_client }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-5"></v-divider>
            <div class="ml-5 pb-1">
              <p class="caption" style="color: #bdbdbd">
                <v-icon>mdi-update</v-icon>Actualizado recientemente
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card> </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      count_peniding_payment: 0,
      count_recived_payment: 0,
      count_deb_client: 0,
      count_total_client: 0,
      reload: false,
    };
  },
  mounted() {
    this.getPendingPayment();
    this.getRecivedPayment();
    this.getTotalClients();
    this.getClientWithDebts();
  },
  methods: {
    getPendingPayment() {
      console.log("entra a obtener pagos pendientes");
      axios
        .get("/pending-payment")
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          this.count_peniding_payment = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener pagos pendientes"
          );
          setTimeout(() => this.$router.push({ name: "payment debt" }), 4000);
        });
    },
    getRecivedPayment() {
      console.log("entra a obtener pagos recibidos");
      axios
        .get("/recived-payment")
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          this.count_recived_payment = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener pagos recibidos"
          );
          setTimeout(
            () => this.$router.push({ name: "payment recived" }),
            4000
          );
        });
    },
    getClientWithDebts() {
      console.log("entra a obtener clientes con deudas");
      axios
        .get("/get-client-with-debts")
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          this.count_deb_client = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener clientes con deduas"
          );
          setTimeout(
            () => this.$router.push({ name: "payment recived" }),
            4000
          );
        });
    },

    getTotalClients() {
      console.log("entra a obtener total de clientes ");
      axios
        .get("/get-total-clients")
        .then((response) => {
          console.log("entra al axios de total de clientes " + response.data);
          //this.displayNotification("success", "Éxito", response.data);
          this.count_total_client = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener total de clientes"
          );
        });
    },
  },
};
</script>


